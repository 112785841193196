import { IDepositsItem } from "types/invoice";
import {
  ISalesOrder,
  ISalesOrderApproval,
  ISalesOrderItem,
  IShipmenSOPickingItem,
  IShipmentPackage,
} from "types/sales-order";
import { Request } from "utils/request";

export const listSalesOrderApi = (payload: {
  backorder?: string;
  items?: string[];
  customers?: any[];
  statuses?: string[];
  methods?: string[];
  textSearch?: string;
  ship_date?: {
    from?: string;
    to?: string;
  };
}) => {
  return Request.call<{ data: ISalesOrder[] }>({
    url: "/sales-order/list",
    method: "PUT",
    data: payload,
  });
};

export const getBackorderItemsApi = () => {
  return Request.call<{ data: any }>({
    url: "/sales-order/export-backorder-item",
    method: "GET",
  });
};

export const getExportSOItemsApi = (payload: {
  fromDate: string;
  toDate: string;
  limit?: number;
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/export-sales-item",
    method: "PUT",
    data: payload,
  });
};

export const getShipCarrierApi = () => {
  return Request.call<{ data: ISalesOrder[] }>({
    url: "/sales-order/ship-carrier",
    method: "GET",
  });
};

export const listSalesOrderPickApi = (payload: { status: string }) => {
  return Request.call<{ data: ISalesOrder[] }>({
    url: "/sales-order/list/pps?status=" + payload.status,
    method: "GET",
  });
};

export const createSalesOrderApi = (payload: ISalesOrder) => {
  return Request.call<{ data: ISalesOrder }>({
    url: "/sales-order/create",
    method: "PUT",
    data: payload,
  });
};

export const updateCustomerUPSApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/customer/update/ups",
    method: "PUT",
    data: payload,
  });
};

export const getDetailSalesOrderApi = (payload: { id: any }) => {
  return Request.call<{ data: ISalesOrder }>({
    url: "/sales-order/detail/" + payload.id,
    method: "GET",
  });
};

export const cancelSalesOrderApi = (payload: {
  so_id: any;
  is_fee?: boolean;
  reason?: string;
}) => {
  return Request.call<{
    data: {
      so_id: any;
    };
  }>({
    url: "/sales-order/cancel",
    method: "PUT",
    data: payload,
  });
};

export const voidHoldOrderApi = (payload: { so_id: any }) => {
  return Request.call<{
    data: {
      so_id: any;
    };
  }>({
    url: "/sales-order/hold-on",
    method: "PUT",
    data: payload,
  });
};

export const deleteSalesOrderApi = (payload: { so_id: any }) => {
  return Request.call<{
    data: {
      so_id: any;
    };
  }>({
    url: "/sales-order/delete",
    method: "PUT",
    data: payload,
  });
};

export const approveSalesOrderApi = (payload: {
  so_id: any;
  workflow?: string;
  status?: string;
}) => {
  return Request.call<{
    data: {
      so_id: any;
    };
  }>({
    url: "/sales-order/approval/active",
    method: "PUT",
    data: payload,
  });
};

export const denySalesOrderApi = (payload: {
  so_id: any;
  workflow?: string;
  deny_reason?: string;
}) => {
  return Request.call<{
    data: {
      so_id: any;
    };
  }>({
    url: "/sales-order/approval/deny",
    method: "PUT",
    data: payload,
  });
};

export const getApprovalsSalesOrderApi = (payload: { so_id: any }) => {
  return Request.call<{ data: ISalesOrderApproval[] }>({
    url: "/sales-order/approval/list",
    method: "PUT",
    data: payload,
  });
};

export const updateSOShipSectionApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/update/ship-section",
    method: "PUT",
    data: payload,
  });
};

export const updateSOAddressSectionApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/update/address-section",
    method: "PUT",
    data: payload,
  });
};

export const updateSOInternalNoteApi = (payload: {
  id: any;
  internal_note?: string;
  note?: string;
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/update/internal-note",
    method: "PUT",
    data: payload,
  });
};

export const applySOPromoApi = (payload: {
  so_id: any;
  promo_code: string;
  is_manual?: boolean;
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/apply/promo",
    method: "PUT",
    data: payload,
  });
};

export const getSOHistoryApi = (payload: { source_id: any }) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/history",
    method: "PUT",
    data: payload,
  });
};

export const getSOPackagesApi = (payload: { so_id: any }) => {
  return Request.call<{ data: IShipmentPackage[] }>({
    url: "/sales-order/package/list",
    method: "PUT",
    data: payload,
  });
};

export const getPackageUPSRateApi = (payload: {
  so_id: any;
  isLabel?: boolean;
  packages: any[];
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/package/ups",
    method: "PUT",
    data: payload,
  });
};

export const getSOUPSSummaryApi = () => {
  return Request.call<{ data: any }>({
    url: "/sales-order/list/ups-summary",
    method: "GET",
  });
};

export const unpickPackageApi = (payload: {
  so_id: any;
  package_id: any;
  serial_number: any;
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/package/unpick",
    method: "PUT",
    data: payload,
  });
};

export const moveItemPackageApi = (payload: {
  so_id: any;
  from_id: any;
  to_id: any;
  line_id: any;
  serial_numbers: any[];
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/package/move-item",
    method: "PUT",
    data: payload,
  });
};

export const savePackageUPSSyncApi = (payload: {
  ups_shipment_id?: string;
  shipping_cost?: Number;
  so_id: any;
  packages?: any[];
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/package/ups-sync",
    method: "PUT",
    data: payload,
  });
};

export const createSOPackageApi = (payload: IShipmentPackage) => {
  return Request.call<{ data: IShipmentPackage }>({
    url: "/sales-order/package/create",
    method: "PUT",
    data: payload,
  });
};

export const updateSOPackageApi = (payload: IShipmentPackage) => {
  return Request.call<{ data: IShipmentPackage }>({
    url: "/sales-order/package/update",
    method: "PUT",
    data: payload,
  });
};

export const deleteSOPackageApi = (payload: { id: any; so_id: any }) => {
  return Request.call<{ data: IShipmentPackage }>({
    url: "/sales-order/package/delete",
    method: "PUT",
    data: payload,
  });
};

export const markAsShippedMultiPackageApi = (payload: {
  ids: any[];
  so_id: any;
}) => {
  return Request.call<{ data: IShipmentPackage }>({
    url: "/sales-order/package/mask-as-shipped-multi",
    method: "PUT",
    data: payload,
  });
};

export const undoToPackingApi = (payload: { ids: any[]; so_id: any }) => {
  return Request.call<{ data: IShipmentPackage }>({
    url: "/sales-order/package/undo-packing",
    method: "PUT",
    data: payload,
  });
};

export const completePackingApi = (payload: { ids: any[]; so_id: any }) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/package/complete-packing",
    method: "PUT",
    data: payload,
  });
};

export const voidUpsShipmentApi = (payload: {
  tracking_numbers: any[];
  so_id: any;
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/package/void-ups-shipment",
    method: "PUT",
    data: payload,
  });
};

export const getPickingItemsListApi = (payload: {
  package_id: any;
  so_id: any;
}) => {
  return Request.call<{ data: IShipmenSOPickingItem[] }>({
    url: "/sales-order/package/picking-list",
    method: "PUT",
    data: payload,
  });
};

export const pickSerialNumberApi = (payload: {
  package_id: any;
  so_id: any;
  serial_number: string;
  is_manual?: boolean;
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/package/pick-serial",
    method: "PUT",
    data: payload,
  });
};

export const shipSOPackageApi = (payload: { id: any; so_id: any }) => {
  return Request.call<{ data: IShipmentPackage }>({
    url: "/sales-order/package/ship",
    method: "PUT",
    data: payload,
  });
};

export const completePickPackageApi = (payload: { id: any; so_id: any }) => {
  return Request.call<{ data: IShipmentPackage }>({
    url: "/sales-order/package/picked",
    method: "PUT",
    data: payload,
  });
};

export const createSODepositApi = (
  payload: IDepositsItem & {
    isReceive?: boolean;
  }
) => {
  return Request.call<{ data: IDepositsItem }>({
    url: payload?.id
      ? "/sales-order/deposit/update"
      : "/sales-order/deposit/create",
    method: "PUT",
    data: payload,
  });
};

export const deleteSODepositApi = (payload: { so_id: any; id: any }) => {
  return Request.call<{ data: IDepositsItem }>({
    url: "/sales-order/deposit/delete",
    method: "PUT",
    data: payload,
  });
};

export const getSODepositListApi = (payload: { so_id: any }) => {
  return Request.call<{ data: IDepositsItem[] }>({
    url: "/sales-order/deposit/list",
    method: "PUT",
    data: payload,
  });
};

export const splitSOItemApi = (payload: { so_id: any }) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/split-order",
    method: "PUT",
    data: payload,
  });
};

export const updateSOItemApi = (payload: {
  so_id: any;
  id?: any;
  item_id: any;
  quantity?: number;
  cost?: number;
  discount?: number;
  percent?: number;
  note?: string;
  is_delete?: boolean;
}) => {
  return Request.call<{ data: ISalesOrderItem }>({
    url: "/sales-order/items/update",
    method: "PUT",
    data: payload,
  });
};

export const markBackOrderPickApi = (payload: { id: any }) => {
  return Request.call<{ data: ISalesOrder }>({
    url: "/sales-order/backorder-to-pick",
    method: "PUT",
    data: payload,
  });
};

export const changeBackorderToSentEmailApi = (payload: { id: any }) => {
  return Request.call<{ data: ISalesOrder }>({
    url: "/sales-order/backorder-to-sent-email",
    method: "PUT",
    data: payload,
  });
};

export const approveBackorderApi = (payload: { id: any }) => {
  return Request.call<{ data: ISalesOrder }>({
    url: "/sales-order/backorder-approve",
    method: "PUT",
    data: payload,
  });
};

export const getOpenActionApi = () => {
  return Request.call<{ data: any }>({
    url: "/sales-order/open-action",
    method: "GET",
  });
};

export const getsaleMonthlyApi = () => {
  return Request.call<{ data: any }>({
    url: "/sales-order/report/monthly",
    method: "GET",
  });
};

export const completeOrderApi = (payload: { id: any }) => {
  return Request.call<{ data: ISalesOrder }>({
    url: "/sales-order/complete-order",
    method: "PUT",
    data: payload,
  });
};

export const printSOApi = (payload: any) => {
  const type = payload?.printType;
  let url = "";
  if (type === "SO") {
    url = "/sales-order/print";
  }

  if (type === "QUOTATION") {
    url = "/sales-order/print";
  }

  if (type === "PICK") {
    url = "/sales-order/print/picking-list";
  }

  if (type === "PACK") {
    url = "/sales-order/print/packing-slip";
  }

  if (type === "PICK_UP_SLIP") {
    url = "/sales-order/print/pick-up-slip";
  }

  if (type === "INVOICE") {
    url = "/sales-order/print/invoice";
  }

  if (type === "STATEMENT") {
    url = "/sales-order/print/statement";
  }

  return Request.call<{ data: any }>(
    {
      url,
      method: "PUT",
      data: payload,
    },
    true
  );
};

export const approveSOFullApi = (payload: { so_id: any }) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/approve-full",
    method: "PUT",
    data: payload,
  });
};

export const updateSOTaxApi = (payload: {
  so_id: any;
  tax_percent: number;
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/update-tax",
    method: "PUT",
    data: payload,
  });
};

export const updateSODropshipApi = (payload: {
  id: any;
  is_dropship: boolean;
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/update-drop-ship",
    method: "PUT",
    data: payload,
  });
};

export const getDailySalesReportApi = (payload: {
  fromDate: string;
  toDate: string;
}) => {
  return Request.call<{ data: any }>({
    url: "/sales-order/get-daily-sales-report",
    method: "PUT",
    data: payload,
  });
};
