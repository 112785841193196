// eslint-disable-next-line import/named
import BaseAxios, { AxiosInstance } from "axios";
import _ from "lodash";
import { store } from "redux-store";
import { clearSecretCode } from "redux-store/reducers/auth";
import ROUTERS from "routers/constants";
import { tabUUID } from "./app-events";
import notification from "./notification";
const HIDE_MESSAGE_ERROR_CODES = [404, 500, 9999];

export type IRequestResponse<ResponseObj> = {
  apiStatus: 0 | 1;
  errorStatus?: any;
  message?: any;
} & ResponseObj extends infer U
  ? { [K in keyof U]: U[K] }
  : never;

const handleResponseHeader = (responseHeader?: any) => {
  try {
    const notTrusted = responseHeader?.["x-trusted"] === "NoTrust";
    if (notTrusted) {
      store.dispatch(clearSecretCode());

      setTimeout(() => {
        window.location.href = ROUTERS.COMPANY_VERIFY;
      }, 500);
      return;
    }

    const serverAppVersion = responseHeader?.["x-application-version"];
    notification.newVersionWarning(serverAppVersion);
  } catch (e) {
    console.error("handleResponseHeader error", e);
  }
};

class RequestClass {
  axios: AxiosInstance;
  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 });
  }
  async call<ResponseObj>(
    config: {
      url: string;
      method: string;
      data?: any;
      serverBaseUrl?: string;
      headers?: any;
    },
    isRaw?: boolean
  ) {
    try {
      let serverBaseUrl =
        config.serverBaseUrl || process.env.REACT_APP_PUBLIC_API_HOST;

      if (process.env.NODE_ENV !== "production") {
        serverBaseUrl = "http://localhost:3001/api";
      }

      const storageSession = _.get(store.getState(), "auth.session");
      const session = {
        access_token: storageSession?.access_token,
        secret_code: storageSession?.secret_code,
      };

      const token = session?.access_token;

      if (!config.headers) {
        config = {
          ...config,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-browser-id": tabUUID,
            "x-google-version": session?.secret_code,
          },
        };
      }
      if (token) {
        config = {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          },
        };
        if (config?.method === "GET") {
          config.data = undefined;
        }
      }

      const res = await this.axios.request({
        baseURL: serverBaseUrl,
        ...config,
        responseType: isRaw ? "blob" : "json",
      });

      if (isRaw) {
        return res as unknown as IRequestResponse<ResponseObj>;
      }
      handleResponseHeader(res?.headers);
      return { ...res.data, apiStatus: 1 } as IRequestResponse<ResponseObj>;
    } catch (error: any) {
      handleResponseHeader(error?.response?.headers);
      const errorStatus: any = _.get(error, "response.status", null);
      const data: any = _.get(error, "response.data", {}) || {};

      data.message = data.message || data.data?.message;
      if (
        HIDE_MESSAGE_ERROR_CODES.includes(errorStatus) ||
        HIDE_MESSAGE_ERROR_CODES.includes(data?.code) ||
        !data.message
      ) {
        data.message = "Oops, Something Went Wrong";
      }
      if (_.get(error, "message") === "Network Error") {
        data.message = "Network Error";
        data.code = 444;
      }

      return {
        ...data,
        apiStatus: 0,
        errorStatus,
      } as unknown as IRequestResponse<ResponseObj>;
    }
  }
}

export const Request = new RequestClass();
