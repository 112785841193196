import { IArea, IWarehouse } from "types/warehouse";
import { Request } from "utils/request";

export const listWarehouseApi = () => {
  return Request.call<{ data: IWarehouse[] }>({
    url: "/warehouse/list",
    method: "GET",
  });
};

export const createWarehouseApi = (payload: IWarehouse) => {
  return Request.call<{ data: IWarehouse }>({
    url: "/warehouse/create",
    method: "PUT",
    data: payload,
  });
};

export const getDetailWarehouseApi = (payload: { id: any }) => {
  return Request.call<{ data: IWarehouse }>({
    url: "/warehouse/detail/" + payload.id,
    method: "GET",
  });
};

export const createWarehouseAreaApi = (payload: IArea) => {
  return Request.call<{ data: IWarehouse }>({
    url: payload.id ? "/warehouse/area/update" : "/warehouse/area/create",
    method: "PUT",
    data: payload,
  });
};

export const deleteWarehouseAreaApi = (payload: {
  warehouse_id: number;
  id?: any;
}) => {
  return Request.call<{ data: IArea[] }>({
    url: "/warehouse/area/delete",
    method: "DELETE",
    data: payload,
  });
};

export const getWarehouseAreasApi = (payload: { warehouse_id: number }) => {
  return Request.call<{ data: IArea[] }>({
    url: "/warehouse/area/list",
    method: "PUT",
    data: payload,
  });
};


export const receiveItemPalletV2Api = (payload: {
  area_id?: number;
  text: string;
  type: any;
  isConfirm: boolean
}) => {
  return Request.call<{
    data: any;
  }>({
    url: "/purchase-order/warehouse/receive/item/pallet/v2",
    method: "PUT",
    data: payload,
  });
};

export const itemPalletPackingV2Api = (payload: {
  text: string;
}) => {
  return Request.call<{
    data: any;
  }>({
    url: "/purchase-order/pallet/packing/v2",
    method: "PUT",
    data: payload,
  });
};

export const palletGetSerialNumberApi = (payload: {
  pallet_id: any;
  item_id: any;
}) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/pallet/serial-number",
    method: "PUT",
    data: payload,
  });
}


export const palletDeleteSerialNumberApi = (payload: {
  pallet_id: any;
  item_id: any;
  id: any;
}) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/pallet/serial-number/delete",
    method: "PUT",
    data: payload,
  });
}

export const scanItemAllApi = (payload: {
  text: string;
}) => {
  return Request.call<{
    data: {
      html: string
    };
  }>({
    url: "/purchase-order/item-scan",
    method: "PUT",
    data: payload,
  });
};