import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  applySOPromoApi,
  approveBackorderApi,
  approveSOFullApi,
  approveSalesOrderApi,
  cancelSalesOrderApi,
  changeBackorderToSentEmailApi,
  completeOrderApi,
  completePackingApi,
  completePickPackageApi,
  createSODepositApi,
  createSOPackageApi,
  createSalesOrderApi,
  deleteSODepositApi,
  deleteSOPackageApi,
  deleteSalesOrderApi,
  denySalesOrderApi,
  getApprovalsSalesOrderApi,
  getBackorderItemsApi,
  getDailySalesReportApi,
  getDetailSalesOrderApi,
  getExportSOItemsApi,
  getOpenActionApi,
  getPackageUPSRateApi,
  getPickingItemsListApi,
  getSODepositListApi,
  getSOHistoryApi,
  getSOPackagesApi,
  getSOUPSSummaryApi,
  getShipCarrierApi,
  getsaleMonthlyApi,
  listSalesOrderApi,
  listSalesOrderPickApi,
  markAsShippedMultiPackageApi,
  markBackOrderPickApi,
  moveItemPackageApi,
  pickSerialNumberApi,
  savePackageUPSSyncApi,
  shipSOPackageApi,
  splitSOItemApi,
  undoToPackingApi,
  unpickPackageApi,
  updateCustomerUPSApi,
  updateSOAddressSectionApi,
  updateSODropshipApi,
  updateSOInternalNoteApi,
  updateSOItemApi,
  updateSOPackageApi,
  updateSOShipSectionApi,
  updateSOTaxApi,
  voidHoldOrderApi,
  voidUpsShipmentApi,
} from "services/sales-order.service";
import { IOpenActionReport, ISalesOrder } from "types/sales-order";

const TYPE = "SALES_ORDER";

export interface ISalesOrderState {
  list: ISalesOrder[];
  pickList: ISalesOrder[];
  openActionReport: IOpenActionReport | null;
}

const initialState: ISalesOrderState = {
  list: [],
  pickList: [],
  openActionReport: null,
};

export const createSalesOrder = createCallApiAsyncAction(
  TYPE + "/createSalesOrder",
  createSalesOrderApi
);
export const getListSalesOrder = createCallApiAsyncAction(
  TYPE + "/getListSalesOrder",
  listSalesOrderApi
);

export const getBackorderItems = createCallApiAsyncAction(
  TYPE + "/getBackorderItems",
  getBackorderItemsApi
);

export const getExportSOItems = createCallApiAsyncAction(
  TYPE + "/getExportSOItems",
  getExportSOItemsApi
);

export const getListSalesOrderPick = createCallApiAsyncAction(
  TYPE + "/getListSalesOrderPick",
  listSalesOrderPickApi
);

export const getDetailSalesOrder = createCallApiAsyncAction(
  TYPE + "/getDetailSalesOrder",
  getDetailSalesOrderApi
);

export const updateCustomerUPS = createCallApiAsyncAction(
  TYPE + "/updateCustomerUPS",
  updateCustomerUPSApi
);

export const cancelSalesOrder = createCallApiAsyncAction(
  TYPE + "/cancelSalesOrder",
  cancelSalesOrderApi
);

export const voidHoldOrder = createCallApiAsyncAction(
  TYPE + "/voidHoldOrder",
  voidHoldOrderApi
);

export const deleteSalesOrder = createCallApiAsyncAction(
  TYPE + "/deleteSalesOrder",
  deleteSalesOrderApi
);

export const approveSalesOrder = createCallApiAsyncAction(
  TYPE + "/approveSalesOrder",
  approveSalesOrderApi
);

export const denySalesOrder = createCallApiAsyncAction(
  TYPE + "/denySalesOrder",
  denySalesOrderApi
);

export const getApprovalsSalesOrder = createCallApiAsyncAction(
  TYPE + "/getApprovalsSalesOrder",
  getApprovalsSalesOrderApi
);

export const updateSOShipSection = createCallApiAsyncAction(
  TYPE + "/updateSOShipSection",
  updateSOShipSectionApi
);

export const updateSOAddressSection = createCallApiAsyncAction(
  TYPE + "/updateSOAddressSection",
  updateSOAddressSectionApi
);

export const updateSOInternalNote = createCallApiAsyncAction(
  TYPE + "/updateSOInternalNote",
  updateSOInternalNoteApi
);

export const applySOPromo = createCallApiAsyncAction(
  TYPE + "/applySOPromo",
  applySOPromoApi
);

export const getSOHistory = createCallApiAsyncAction(
  TYPE + "/getSOHistory",
  getSOHistoryApi
);

export const getSOPackages = createCallApiAsyncAction(
  TYPE + "/getSOPackages",
  getSOPackagesApi
);

export const getPackageUPSRate = createCallApiAsyncAction(
  TYPE + "/getPackageUPSRate",
  getPackageUPSRateApi
);

export const getSOUPSSummary = createCallApiAsyncAction(
  TYPE + "/getSOUPSSummary",
  getSOUPSSummaryApi
);

export const unpickItemFromPackage = createCallApiAsyncAction(
  TYPE + "/unpickPackage",
  unpickPackageApi
);

export const moveItemPackage = createCallApiAsyncAction(
  TYPE + "/moveItemPackage",
  moveItemPackageApi
);

export const savePackageUPSSync = createCallApiAsyncAction(
  TYPE + "/savePackageUPSSync",
  savePackageUPSSyncApi
);

export const createSOPackage = createCallApiAsyncAction(
  TYPE + "/createSOPackage",
  createSOPackageApi
);

export const updateSOPackage = createCallApiAsyncAction(
  TYPE + "/updateSOPackage",
  updateSOPackageApi
);

export const deleteSOPackage = createCallApiAsyncAction(
  TYPE + "/deleteSOPackage",
  deleteSOPackageApi
);

export const markAsShippedMultiPackage = createCallApiAsyncAction(
  TYPE + "/markAsShippedMultiPackage",
  markAsShippedMultiPackageApi
);

export const undoToPacking = createCallApiAsyncAction(
  TYPE + "/undoToPacking",
  undoToPackingApi
);

export const voidUpsShipment = createCallApiAsyncAction(
  TYPE + "/voidUpsShipment",
  voidUpsShipmentApi
);

export const completePacking = createCallApiAsyncAction(
  TYPE + "/completePacking",
  completePackingApi
);

export const getPickingItemsList = createCallApiAsyncAction(
  TYPE + "/getPickingItemsList",
  getPickingItemsListApi
);

export const pickSerialNumber = createCallApiAsyncAction(
  TYPE + "/pickSerialNumber",
  pickSerialNumberApi
);

export const shipSOPackage = createCallApiAsyncAction(
  TYPE + "/shipSOPackage",
  shipSOPackageApi
);

export const completePickPackage = createCallApiAsyncAction(
  TYPE + "/completePickPackage",
  completePickPackageApi
);

export const createSODeposit = createCallApiAsyncAction(
  TYPE + "/createSODeposit",
  createSODepositApi
);

export const deleteSODeposit = createCallApiAsyncAction(
  TYPE + "/deleteSODeposit",
  deleteSODepositApi
);

export const getSODepositList = createCallApiAsyncAction(
  TYPE + "/getSODepositList",
  getSODepositListApi
);

export const updateSOItem = createCallApiAsyncAction(
  TYPE + "/updateSOItem",
  updateSOItemApi
);

export const splitSOItem = createCallApiAsyncAction(
  TYPE + "/splitSOItem",
  splitSOItemApi
);

export const completeOrder = createCallApiAsyncAction(
  TYPE + "/completeOrder",
  completeOrderApi
);

export const approveSOFull = createCallApiAsyncAction(
  TYPE + "/approveSOFull",
  approveSOFullApi
);

export const updateSOTax = createCallApiAsyncAction(
  TYPE + "/updateSOTax",
  updateSOTaxApi
);

export const updateSODropship = createCallApiAsyncAction(
  TYPE + "/updateSODropship",
  updateSODropshipApi
);

export const getShipCarrier = createCallApiAsyncAction(
  TYPE + "/getShipCarrier",
  getShipCarrierApi
);

export const markBackOrderPick = createCallApiAsyncAction(
  TYPE + "/markBackOrderPick",
  markBackOrderPickApi
);

export const changeBackorderToSentEmail = createCallApiAsyncAction(
  TYPE + "/changeBackorderToSentEmail",
  changeBackorderToSentEmailApi
);

export const approveBackorder = createCallApiAsyncAction(
  TYPE + "/approveBackorder",
  approveBackorderApi
);

export const getOpenAction = createCallApiAsyncAction(
  TYPE + "/getOpenAction",
  getOpenActionApi
);

export const getsaleMonthly = createCallApiAsyncAction(
  TYPE + "/getsaleMonthly",
  getsaleMonthlyApi
);

export const getDailySalesReport = createCallApiAsyncAction(
  TYPE + "/getDailySalesReport",
  getDailySalesReportApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListSalesOrder,
      stateKey: "list",
    });

    addListBuilderCase({
      builder,
      fetchAction: getListSalesOrderPick,
      stateKey: "pickList",
    });

    addListBuilderCase({
      builder,
      fetchAction: getOpenAction,
      stateKey: "openActionReport",
    });

    builder.addCase(getDetailSalesOrder.fulfilled, (state, action) => {
      const soDetail = action.payload?.response?.apiStatus
        ? action.payload?.response?.data
        : null;
      if (!soDetail) return;
      const index = state.list.findIndex((item) => item.id === soDetail.id);
      if (index > -1) {
        state.list[index] = soDetail;
      }
    });
  },
  reducers: {},
});

export default slice.reducer;
