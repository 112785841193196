import { Navigate, useMatches } from "react-router-dom";
import { useAppSelector } from "redux-store";
import ROUTERS, { ROUTERS_CUSTOMER } from "routers/constants";
import { getAllowAccessRoute } from "utils/roles";

export const PrivateRoute = ({ Component }: { Component: any }) => {
  const auth = useAppSelector((state) => state.auth);

  const matches = useMatches();

  if (auth?.isCustomer) {
    return <Navigate to={ROUTERS_CUSTOMER.MY_PROFILE} />;
  }

  const matchId = matches[1].id;
  const isAllowAccessRoute = getAllowAccessRoute(matchId as any);

  if (!isAllowAccessRoute) {
    return <Navigate to={ROUTERS.NOT_FOUND} />;
  }

  if (auth?.user && !auth?.session?.secret_code) {
    return <Navigate to={ROUTERS.COMPANY_VERIFY} />;
  }

  return auth?.user ? <Component /> : <Navigate to={ROUTERS.LOGIN} />;
};

export const CustomerPrivateRoute = ({ Component }: { Component: any }) => {
  const auth = useAppSelector((state) => state.auth);

  if (auth.user?.id && !auth?.isCustomer) {
    return <Navigate to={ROUTERS.DASHBOARD} />;
  }

  return auth?.user ? <Component /> : <Navigate to={ROUTERS_CUSTOMER.LOGIN} />;
};

export const PublicRouteOnly = ({
  Component,
  to,
}: {
  Component: any;
  to: string;
}) => {
  const user = useAppSelector((state) => state.auth.user);

  return !user ? <Component /> : <Navigate to={to} />;
};
